import jwtDecode from "jwt-decode";
import { getItem } from "../../../service/LocalStorage";
import { useTranslation } from "react-i18next";

function DeleteActivity({
    idActivityToDelete,
    activityId,
    confirmDelete,
    changeIdToDelete,
    cancelDelete,
    activityUserId
}) {

    const userToken = getItem('userToken');
    let currentUserId = null;
    if(userToken) {
        currentUserId = jwtDecode(userToken).user_id;
    }


    const {t} = useTranslation();
    if (currentUserId !== activityUserId) {
        return null;
    }

    if(idActivityToDelete != activityId) {
        return (
            <a  style={{cursor: "pointer", fontSize: "25px"}} onClick={() => changeIdToDelete(activityId)}>
                <i className="icofont-trash"></i> 
            </a>
        );
    } else {
        return (
            <>
                <a  style={{
                        cursor: "pointer",
                        color: "#f24570", fontSize: "25px"
                    }}
                    onClick={() => confirmDelete(activityId)}
                >

                    &nbsp;<i className="icofont-check-circled"></i>&nbsp;
                </a>
                <a  style={{
                        cursor: "pointer",
                        color: "#f2aaaa", fontSize: "25px"
                    }}
                    onClick={() => cancelDelete()}
                >
                    &nbsp;<i className="icofont-close-circled"></i>&nbsp;
                </a>
            </>    
        );
    }
    

    return null
}

export default DeleteActivity;