import { useContext, useEffect, useState } from 'react';
import { useWindowSize } from '../../service/Window';
import { getItem } from '../../service/LocalStorage';
import Auth from '../../context/Auth';

const { REACT_APP_API_URL } = process.env;

function MediaTevif({pageName}) {
    const userToken = getItem('userToken');
    const {isAuthenticated, setIsAuthenticated} = useContext(Auth);
    const [width, height] = useWindowSize();
    const [poster, setPoster] = useState('');
    const [video, setVideo] = useState('');
    const [link, setLink] = useState('');

    useEffect(() => {  
        async function getPub() {
            let config = { 
                "Content-Type" : "application/json",
            };

            let url = `${REACT_APP_API_URL}/api/public/media-tevif/show?${pageName}`;

            if(isAuthenticated && userToken) {
                config = {
                    "Content-Type" : "application/json",
                    "Authorization": "Bearer "+ userToken 
                };

                url = `${REACT_APP_API_URL}/api/media-tevif/show?${pageName}`;
            }            

            try {
                const userResponse = await fetch(url, {
                    method: "GET",
                    headers: config
                });
                
                const data = await userResponse.json();
                setPoster(data.posterUrl ?? null);
                setVideo(data.videoUrl ?? null);
                setLink(data.linkRedirect ?? null);
            } catch (err) {
                console.error('Error fetching ad data:', err);
            } 
        }

        getPub();

    }, []);

    return (
        <>
            {width > 991 ? (
                <div className="ad-container" style={{ marginBottom: "15px" }}>
                    {video ? (
                        <a href={link} target="_blank" rel="noopener noreferrer">
                            <video 
                                src={video}
                                poster={poster}
                                autoPlay
                                muted
                                loop
                                playsInline
                                style={{
                                    width: '100%',
                                    maxHeight: '235px',
                                    display: 'block',
                                    objectFit: 'cover',
                                    borderRadius: '15px' 
                                }}
                            />
                        </a>
                    ) : poster ? (
                        <a href={link} target="_blank" rel="noopener noreferrer">
                            <img 
                                src={poster}
                                alt="Publicity"
                                style={{
                                    width: '100%',
                                    maxHeight: '235px',
                                    display: 'block',
                                    objectFit: 'cover',
                                    borderRadius: '15px' 
                                }}
                            />
                        </a>
                    ) : null}
                </div>
            ) : width > 767 ? (
                <div className="ad-container" style={{ marginBottom: "15px" }}>
                    {video ? (
                        <a href={link} target="_blank" rel="noopener noreferrer">
                            <video 
                                src={video}
                                poster={poster}
                                autoPlay
                                muted
                                loop
                                playsInline
                                style={{
                                    width: '100%',
                                    maxHeight: '180px',
                                    display: 'block',
                                    objectFit: 'cover',
                                    borderRadius: '15px' 
                                }}
                            />
                        </a>
                    ) : poster ? (
                        <a href={link} target="_blank" rel="noopener noreferrer">
                            <img 
                                src={poster}
                                alt="Publicity"
                                style={{
                                    width: '100%',
                                    maxHeight: '180px',
                                    display: 'block',
                                    objectFit: 'cover',
                                    borderRadius: '15px' 
                                }}
                            />
                        </a>
                    ) : null}
                </div>
            ) : width > 575 ? (
                <div className="ad-container" style={{ marginBottom: "15px" }}>
                    {video ? (
                        <a href={link} target="_blank" rel="noopener noreferrer">
                            <video 
                                src={video}
                                poster={poster}
                                autoPlay
                                muted
                                loop
                                playsInline
                                style={{
                                    width: '100%',
                                    maxHeight: '135px',
                                    display: 'block',
                                    objectFit: 'cover',
                                    borderRadius: '15px' 
                                }}
                            />
                        </a>
                    ) : poster ? (
                        <a href={link} target="_blank" rel="noopener noreferrer">
                            <img 
                                src={poster}
                                alt="Publicity"
                                style={{
                                    width: '100%',
                                    maxHeight: '135px',
                                    display: 'block',
                                    objectFit: 'cover',
                                    borderRadius: '15px' 
                                }}
                            />
                        </a>
                    ) : null}
                </div>
            ) : (
                <div className="ad-container" style={{ marginBottom: "15px" }}>
                    {video ? (
                        <a href={link} target="_blank" rel="noopener noreferrer">
                            <video 
                                src={video}
                                poster={poster}
                                autoPlay
                                muted
                                loop
                                playsInline
                                style={{
                                    width: '100%',
                                    maxHeight: '140px',
                                    display: 'block',
                                    objectFit: 'cover',
                                    borderRadius: '15px' 
                                }}
                            />
                        </a>
                    ) : poster ? (
                        <a href={link} target="_blank" rel="noopener noreferrer">
                            <img 
                                src={poster}
                                alt="Publicity"
                                style={{
                                    width: '100%',
                                    maxHeight: '140px',
                                    display: 'block',
                                    objectFit: 'cover',
                                    borderRadius: '15px' 
                                }}
                            />
                        </a>
                    ) : null}
                </div>
            )}
        </>
    );
};

export default MediaTevif;
