import jwtDecode from "jwt-decode";
import { getItem } from "../../../service/LocalStorage";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import axios from "axios";

function DeleteComment({idComment, setIdCommentToDelete, setNbComments}) {

    const userToken = getItem('userToken');
    let currentUserId = null;
    if(userToken) {
        currentUserId = jwtDecode(userToken).user_id;
    }

    const [confirmDeleteClicked, setConfirmDeleteClicked] = useState(false);

    const {t} = useTranslation();


    const cancelDelete = () => {
        setConfirmDeleteClicked(false);
    }

    const confirmDelete = (idComment) => {
        const config = {     
            headers: { 
                "Authorization": "Bearer "+ userToken 
            }
        }

        axios.delete(`${process.env.REACT_APP_API_URL}/api/comment/${idComment}/delete`, config)
            .then (response => {
                if(response.status == 200) {
                    setIdCommentToDelete(idComment);
                    var data = response.data;
                    setNbComments(data.nbCommentActivity);
                }
            }).catch(err => {

            })
    }


    if(!confirmDeleteClicked) {
        return (
            <>&nbsp;&nbsp;<a  style={{cursor: "pointer", fontSize: "25px"}} onClick={() => setConfirmDeleteClicked(true)}>
                <i className="icofont-trash"></i> 
            </a></>
        );
    } else {
        return (
            <> 
                &nbsp;&nbsp;<a  style={{
                        cursor: "pointer",
                        color: "#f24570",
                        fontSize: "25px"
                    }}
                    onClick={() => confirmDelete(idComment)}
                >
                    <i className="icofont-check-circled"></i>
                </a>&nbsp;&nbsp;&nbsp;
                <a  style={{
                        cursor: "pointer",
                        color: "#f2aaaa",
                        fontWeight: "bold",
                        fontSize: "25px"
                    }}
                    onClick={() => cancelDelete()}
                >
                    <i className="icofont-close-circled"></i>
                </a>
            </>
        );
    }
    

    return null
}

export default DeleteComment;