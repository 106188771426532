import { useEffect, Fragment, useContext, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Header from "../../component/layout/headertwo";
import { getItem, setItem } from '../../service/LocalStorage';
import jwtDecode from 'jwt-decode';
import Auth from "../../context/Auth";
import LikeMember from "../../component/sidebar/like-member";
import Media from "./media";
import ActivityUser from './activity';

import { getInitialMedias, getInitialUserData } from "../../service/User";
import Footer from "../../component/layout/mobilefooter";
import { useWindowSize } from "../../service/Window";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import UserFollowers from "./profileUser/followers";
import AppSectionTwo from "../../component/section/appsectiontwo";
import LightMode from "../../context/LightMode";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { getCityNameByLang, getCountryNameByLang } from "../../service/CityApi";
import { Clipboard } from "@capacitor/clipboard";
import InitializeAndResumeBanner from "../../component/adMob/initializeBanner";
import TevifSpinner from "../../component/spinner/tevifSpinner";
import { showInterstitial } from "../../component/adMob/adMob";
import { getProfileName } from "../../service/String";
import MediaTevif from "../../component/MediaTevif/MediaTevif";

const {REACT_APP_API_URL, REACT_APP_FRON_URL} = process.env;

function ProfileUser() {
    const navigate = useNavigate();
    const currentLanguage = getItem('i18nextLng') ?? "en";

    const {isAuthenticated, setIsAuthenticated} = useContext(Auth); 
    const { isLightMode, setIsLightMode } = useContext(LightMode);

    let { username } = useParams();    
    let userToken = getItem('userToken');
    let currentUserIdentifier = null;
    if(userToken) {
        currentUserIdentifier = jwtDecode(userToken).username;
    }

    const [user, setUser] = useState(getInitialUserData());
    const [activities, setActivities] = useState([]);
    const [similarList, setSimilarList] = useState([]);
    const [medias, setMedias] = useState(getInitialMedias());
    const [totalMedia, setTotalMedia] = useState(0);
    const [spinnerActivities, setSpinnerActivities] = useState(false);

    const [isFollow, setIsFollow] = useState(false);
    const [prettyNbFollow, setPrettyNbFollow] = useState("0");
    const [showFollow, setShowFollow] = useState(true);
    const [width, height] = useWindowSize();
    const [newSelectedUser, setNewSelectedUser] = useState("");
    
    const [showBlock, setShowBlock] = useState(true);
    const [isBlock, setIsBlock] = useState(false);

    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if(isAuthenticated && currentUserIdentifier == username) {
            navigate('/profile');
        }
        
        setCopied(false);
        changeProfileUser(username, false);
        
    }, [username]);
    
    useEffect(() => {     
        if(newSelectedUser) {
            changeProfileUser(newSelectedUser, true);
            setCopied(false);
        }     
        
    }, [newSelectedUser]);

    async function changeProfileUser(name, goToNavigate) {
        //////////////////////
        let nbUserProfileShowed = getItem('nbUserProfileShowed');
        if(!nbUserProfileShowed) {
            nbUserProfileShowed = 0;
        }
        nbUserProfileShowed = parseInt(nbUserProfileShowed);

        if(nbUserProfileShowed > 100) {
            nbUserProfileShowed = 0;
        } else {
            nbUserProfileShowed = nbUserProfileShowed + 1;
        }

        if (nbUserProfileShowed != 0 && nbUserProfileShowed % 5 === 0) {
            showInterstitial();
        }

        setItem('nbUserProfileShowed', nbUserProfileShowed);
        ///////////////////////
        let url = '';
        let config = {
            "Content-Type" : "application/json",
        }
                  
        if(isAuthenticated) {
            config = { 
                "Content-Type" : "application/json",
                "Authorization": "Bearer "+ userToken 
            };

            url = `${REACT_APP_API_URL}/api/users/show/`+name;
        } else {
            url = `${REACT_APP_API_URL}/api/users/public/show/`+name;
        }

        if(goToNavigate) {
            navigate(`/profile/${name}`);
        }
        
        try {
            setSpinnerActivities(true);
            setActivities([]);
            setMedias(getInitialMedias());
            setUser(getInitialUserData());
            setTotalMedia(0);
            let userData = getInitialUserData();

            const userResponse = await fetch(url, {
                method: "GET",
                headers: config
            })
            
            if(userResponse.status === 404) {
                navigate('/user-not-found');
            }

            userData = await userResponse.json();
            setUser(userData);
            let activitiesData =  userData.activities.items;
            setActivities(activitiesData);
            setMedias(userData.mediasInfo);
            setTotalMedia(userData.mediasInfo.totalMedia);
            setSimilarList(userData.similar.items);
            setSpinnerActivities(false);
            setIsFollow(userData.currentUserIsfollow);
            setPrettyNbFollow(userData.prettyNbFollow);
            setIsBlock(userData.blockedByCurrentUser);
        } catch (err) {
            navigate('/user-not-found');
        } 
    }

    const updateIsFollow = () => {
        setShowFollow(false);
        fetch(`${REACT_APP_API_URL}/api/users/follow`, {
            method: "POST",
            body: JSON.stringify({
                "user_target": user.username
            }),
            headers: { 
                "Content-Type" : "application/json",
                "Authorization": "Bearer "+ userToken 
            }
        })
        .then(response => response.json())
        .then((result) => {
            setShowFollow(true);
            setIsFollow(result.follow);
            setPrettyNbFollow(result.prettyNbFollow);
        });
    }


    const updateIsBlock = () => {
        setShowBlock(false);
        fetch(`${REACT_APP_API_URL}/api/users/block`, {
            method: "POST",
            body: JSON.stringify({
                "user_target": user.username
            }),
            headers: { 
                "Content-Type" : "application/json",
                "Authorization": "Bearer "+ userToken 
            }
        })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } 

            return Promise.reject(response);
        }). then((jsonData) => {
            setShowBlock(true);
            setIsBlock(jsonData.block);
        }).catch((response) => {
            setShowBlock(true);
          });
        ;
    }

    let marginValue = "35px";
    if (process.env.REACT_APP_IS_IOS_APP === "true") {
        marginValue = "55px";
    }

    const {t} = useTranslation();


    async function copyLinkProfileUser() {
        let uri = `${REACT_APP_FRON_URL}/profile/${encodeURIComponent(username)}`;
        await Clipboard.write({string: uri});
        setCopied(true);

        setTimeout(function(){
            setCopied(false);
        }, 5000);
    }

    return (
        <Fragment>
            <Helmet>
                <title>Tevif | {user.username} | {t('live_chat')}</title>
            </Helmet>
            <Header />
            <InitializeAndResumeBanner />
            <section className="profile-section padding-tb banner-admob-margin" style={width < 576 ? {marginTop: marginValue} : {}}>
                <div className="container">
                    <div className="section-wrapper">
                        <div className="member-profile">
                            <div className="profile-item">
                                <div className="profile-cover">
                                    {isLightMode ? (<img src="../assets/images/profile/transparent_cover_light_mode.jpg" alt="cover-pic"/>) : (<img src="../assets/images/profile/transparent_cover.jpg" alt="cover-pic"/>)}
                                </div>
                                <div className="profile-information">
                                    <div className="profile-pic">
                                        {user.publicPathProfilePicture ? (
                                            <PhotoProvider>
                                            <PhotoView src={user.publicPathProfilePicture}>
                                                <img 
                                                    src={user.publicPathProfilePicture} 
                                                    alt="profile-image"
                                                />
                                            </PhotoView>
                                        </PhotoProvider>
                                        ) : null}
                                    </div>
                                    <div className="profile-name">
                                        <h4>
                                            {getProfileName(user.username, user.firstName ?? '', user.lastName ?? '')}
                                            {user.verified ? (<i className="icofont-check verified-user" title="Verify Profile"></i>) : null }
                                        </h4>
                                        {user.activeDateAgo ? <p>{t('active')} {user.activedDateInArray.int_val ? (
                                            <>{t('ago')}&nbsp;{user.activedDateInArray.int_val}</>
                                        ) : null} {t(user.activedDateInArray.str_val)}</p> : null}
                                    </div>
                                    {isAuthenticated ? (
                                        <ul className="profile-contact">
                                            <li>
                                                <Link to={`/conversations/${user.username}`}>
                                                    <div className="icon"><i className="icofont-envelope"></i></div>
                                                    <div className="text">
                                                        <p>{t('private_message')}</p>
                                                    </div>
                                                </Link>
                                            </li>
                                            {showFollow ? (
                                                <li onClick={() => {updateIsFollow()}} style={{cursor: "pointer"}}>
                                                    <a>
                                                        <div className="icon">
                                                            <i className={isFollow ? "icofont-check" : "icofont-plus"}></i>
                                                        </div>
                                                        <div className="text">
                                                            <p style={isFollow ? {color: "#f24570"} : null}>&nbsp;{t('followers')}</p>
                                                        </div>
                                                    </a>
                                                </li>
                                                ):(
                                                <li>
                                                    <a>
                                                        <div className="icon">
                                                        </div>
                                                        <div className="text"></div>
                                                    </a>
                                                </li>
                                            )}
                                            <li>
                                                {copied ? (
                                                    <a onClick={copyLinkProfileUser} style={{cursor: "pointer"}}>
                                                        <div className="icon"><i className="icofont-verification-check"></i></div>
                                                        <div className="text">
                                                            <p>{t('copied')}</p>
                                                        </div>
                                                    </a>
                                                ) : (
                                                    <a onClick={copyLinkProfileUser} style={{cursor: "pointer"}}>
                                                        <div className="icon"><i className="icofont-share"></i></div>
                                                        <div className="text">
                                                            <p>{t('share_profile')}</p>
                                                        </div>
                                                    </a>
                                                )}
                                            </li>
                                        </ul>
                                    ) : null}
                                </div>
                            </div>
                            <div className="profile-details">
                                <nav className="profile-nav">
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <button className="nav-link active" id="nav-ativity-tab" data-bs-toggle="tab"
                                            data-bs-target="#activity" type="button" role="tab" aria-controls="activity"
                                            aria-selected="true">{t('activity')}</button>
                                        <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab"
                                            data-bs-target="#profile" type="button" role="tab" aria-controls="profile"
                                            aria-selected="false">{t('profile')}</button>
                                        <button className="nav-link" id="nav-media-tab" data-bs-toggle="tab" data-bs-target="#media"
                                            type="button" role="tab" aria-controls="media" aria-selected="false">{t('media')} <span
                                                className="item-number">{totalMedia}</span></button>
                                        <button className="nav-link" id="nav-userfollowers-tab" data-bs-toggle="tab" data-bs-target="#userfollowers"
                                            type="button" role="tab" aria-controls="userfollowers" aria-selected="false">{t('followers')} <span
                                                className="item-number">{prettyNbFollow}</span></button>
                                    </div>
                                </nav>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane activity-page fade show active" id="activity" role="tabpanel">
                                        <div>
                                            <div className="row">
                                                <div className="col-xl-8">
                                                    <article>
                                                    <MediaTevif pageName={'profileUser'} />
                                                        <div className="activity-tab">
                                                            {spinnerActivities ? (
                                                                <TevifSpinner/>
                                                            ):(
                                                                <ActivityUser 
                                                                    userProfile={user}
                                                                    activities={activities}
                                                                    setActivities={setActivities}
                                                                    setMedias={setMedias}
                                                                    isOwner={false}
                                                                    isAuthenticated={isAuthenticated}
                                                                />
                                                            )}
                                                        </div>
                                                    </article>
                                                </div>

                                                <div className="col-xl-4">
                                                    <aside className="mt-5 mt-xl-0">
                                                        <LikeMember
                                                            similarList={similarList}
                                                            setProfileUsername={setNewSelectedUser}
                                                        />
                                                    </aside>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                        <div>
                                            <div className="row">
                                                <div className="col-xl-8">
                                                    <article>
                                                        <div className="info-card mb-20">
                                                            <div className="info-card-title" style={{display: "flex", justifyContent: "space-between"}}>
                                                                <h6 style={{display:"inline-block", marginRight:"10px"}}>{t('general_informations')}</h6>
                                                                {showBlock ? (
                                                                    <button onClick={() => {updateIsBlock()}} className="lab-btn" style={{
                                                                        display: "inline-block",
                                                                        border: "none",
                                                                        lineHeight: "36px",
                                                                        textTransform: "uppercase",
                                                                        padding: "0 30px",
                                                                        background: "#f24570"
                                                                        }}>{isBlock ? (<>{t('unblock')}</>) : (<>{t('block_this_profile')}</>)} <i className={isBlock ? "icofont-eye" : "icofont-ui-block"}></i></button>
                                                                ) : null}
                                                            </div>
                                                            <div className="info-card-content">
                                                                {isBlock ? (
                                                                    <p className="info-details" style={{color: "#f24570"}}>{t('description_blocked_user')}</p>
                                                                ) : null}
                                                                <ul className="info-list">
                                                                    <li>
                                                                        <p className="info-name">{t('name')}</p>
                                                                        <p className="info-details">{user.firstName} {user.lastName}</p>
                                                                    </li>
                                                                    <li>
                                                                        <p className="info-name">{t('gender')}</p>
                                                                        <p className="info-details"> {(() => {
                                                                            if (user.gender == 1) {
                                                                                return (t('male'));
                                                                            } else if (user.gender === 2) {
                                                                                return (t('female'));
                                                                            } else {
                                                                                return (t('other'));
                                                                            }
                                                                        })()}</p>
                                                                    </li>
                                                                    <li>
                                                                        <p className="info-name">{t('age')}</p>
                                                                        <p className="info-details">{user.age}</p>
                                                                    </li>
                                                                    <li>
                                                                        <p className="info-name">{t('city')}</p>
                                                                        <p className="info-details">{getCityNameByLang(user.city, currentLanguage)},&nbsp;{getCountryNameByLang(user.city.country, currentLanguage)}</p>
                                                                    </li>
                                                                    <li>
                                                                        <p className="info-name">{t('followers')}</p>
                                                                        <p className="info-details">{user.prettyNbFollow}</p>
                                                                    </li>
                                                                    <li>
                                                                        <p className="info-name">{t('city_of_birth')}</p>
                                                                        <p className="info-details">
                                                                            {user.cityOfBirth ? (
                                                                                <>{getCityNameByLang(user.cityOfBirth, currentLanguage)},&nbsp;{getCountryNameByLang(user.cityOfBirth.country, currentLanguage)}</>
                                                                            ) : null}
                                                                        </p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="info-card mb-20">
                                                            <div className="info-card-title">
                                                                <h6>{t('the_languages_spoken')}</h6>
                                                            </div>
                                                            <div className="info-card-content">
                                                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">    
                                                                {user.languages.map((language, i) => (
                                                                    <li className="nav-item" key={`li_lang_${i}`}>
                                                                        <span style={{
                                                                            cursor:"pointer", 
                                                                            borderRadius: "5px", padding: "16px", "&:hover": {color: "#ffffff" },
                                                                            width: "100%", lineHeight: "56px"}}>
                                                                                <img src={`${REACT_APP_API_URL}/uploads/flags/${language.flag}`} style={{
                                                                                    width: "40px"
                                                                                    }}/> 
                                                                        </span>
                                                                    </li>
                                                                ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="info-card mb-20">
                                                            <div className="info-card-title">
                                                                <h6>{t('description')}</h6>
                                                            </div>
                                                            <div className="info-card-content">
                                                                <p>{user.description}</p>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>

                                                <div className="col-xl-4">
                                                    <aside className="mt-5 mt-xl-0">
                                                        <LikeMember
                                                            similarList={similarList}
                                                            setProfileUsername={setNewSelectedUser}
                                                        />
                                                        {/*<FilterSearch user={user}/>*/}
                                                    </aside>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Media 
                                        medias={medias} 
                                        userId={user.id} 
                                        isAuthenticated={isAuthenticated}
                                        setTotalMedia={setTotalMedia}
                                        totalMedia={totalMedia}
                                        isOwner={false}
                                        user={user}
                                        similarList={similarList}
                                        setProfileUsername={setNewSelectedUser}
                                    />
                                    <UserFollowers username={username} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <AppSectionTwo />
            <Footer />
        </Fragment>
    );
}
 
export default ProfileUser;