import { Fragment, useEffect, useRef, useState } from "react";

import Header from "../component/layout/headertwo";
import { useWindowSize } from "../service/Window";
import { getHeadersApiListingUsers } from "../service/User";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { getElementOfArrayById } from "../service/ArrayMerge";
import { useTranslation } from "react-i18next";
import { Clipboard } from '@capacitor/clipboard';
import InitializeAndHideBanner from "../component/adMob/initializeAndHideBanner";
import { showInterstitial } from "../component/adMob/adMob";
import { getItem, setItem } from "../service/LocalStorage";
import jwtDecode from "jwt-decode";
import { getProfileName } from "../service/String";
import SignalActivity from "./user/activity/signal";

const {REACT_APP_API_URL, REACT_APP_IS_IOS_APP, REACT_APP_FRON_URL} = process.env;

export default function ThemeReels() {
    const currentLanguage = getItem('i18nextLng') ?? "en";
    let userToken = getItem('userToken');
    let currentUserIdentifier = null
    if(userToken) {
        currentUserIdentifier = jwtDecode(userToken).username;
    }
    let { themeId } = useParams();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const idReel = queryParams.get('id');

    const videoRef = useRef(null);
    const [width, height] = useWindowSize();
    const [heightVideo, setHeightVideo] = useState(500);
    const [showHeart, setShowHeart] = useState(true);
    const [firstCall, setFirstCall] = useState(false);
    const [copied, setCopied] = useState(false);

    // Reel infos
    const [reel, setReel] = useState(
        {
            publicPosterUrl: "",
            prettyNbLikes: "",
            prettyNbViews: "",
            id: null,
            token: "",
            likedByCurrentUser: false,
            user: null
        }
    );

    const [currentPosition, setCurrentPosition] = useState(0);
    const [reelIds, setReelIds] = useState([]);    
    const apiHeaders = getHeadersApiListingUsers();
    
    function getReel(idReel) {
        let url = `${REACT_APP_API_URL}/api/reel/get-one?theme=${themeId}`; 
        if(idReel) {
            url = `${REACT_APP_API_URL}/api/reel/get-one?theme=${themeId}&id=${idReel}`;
        }

        fetch(url, {
            method: "GET",
            headers: apiHeaders,
        })
        .then(response => response.json())
        .then((result) => {
            setReel(result);
            setReelIds([...reelIds, result.id]);
        }).catch((error) => {
        });
    }

    function getReelById(idReelByPosition) {
        fetch(`${REACT_APP_API_URL}/api/reel/get-one?id=${idReelByPosition}`, {
            method: "GET",
            headers: apiHeaders,
        })
        .then(response => response.json())
        .then((result) => {
            setReel(result);
        }).catch((error) => {
        });
    }
    
    useEffect(() => {
        setFirstCall(true);
        if(firstCall) {
            getReel(idReel);

            let nbUserShowReelClicked = getItem('nbUserShowReelClicked');
            if(!nbUserShowReelClicked) {
                nbUserShowReelClicked = 0;
            }
    
            nbUserShowReelClicked = parseInt(nbUserShowReelClicked);
    
            if(nbUserShowReelClicked > 100) {
                nbUserShowReelClicked = 0;
            } else {
                nbUserShowReelClicked = nbUserShowReelClicked + 1;
            }
    
            if (nbUserShowReelClicked != 0 && nbUserShowReelClicked % 5 === 0) {
                showInterstitial();
            }
    
            setItem('nbUserShowReelClicked', nbUserShowReelClicked);
        }
    }, [firstCall]);

    useEffect(() => {
        setHeightVideo(height - 250);
    }, [height]);

    useEffect(() => {
      let options = {
        rootMargin: "0px",
        threshold: 1
      };
  
      let handlePlay = (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
              videoRef.current.play()
                .then(() => {}).catch(error => {
                });   
          } else {
              if(videoRef && videoRef.current) {
                  //videoRef.current.pause();
              }
          }
        });
      };
  
      let observer = new IntersectionObserver(handlePlay, options);
  
      observer.observe(videoRef.current);
  
    });

    const goRight = () => {
        setCopied(false);
        let newPosition = currentPosition + 1;
        let nextId = getElementOfArrayById(reelIds, newPosition);
        if(nextId) {
            getReelById(nextId);    
        } else {
            let url = `${REACT_APP_API_URL}/api/reel/get-one?theme=${themeId}&idsAlreadySeen=${reelIds}`; 
            fetch(url, {
                method: "GET",
                headers: apiHeaders,
            })
            .then(response => response.json())
            .then((result) => {
                setReel(result);
                setReelIds([...reelIds, result.id]);
            }).catch((error) => {
            });
        }

        setCurrentPosition(newPosition);
        let nbUserShowReelClicked = getItem('nbUserShowReelClicked');
        if(!nbUserShowReelClicked) {
            nbUserShowReelClicked = 0;
        }

        nbUserShowReelClicked = parseInt(nbUserShowReelClicked);

        if(nbUserShowReelClicked > 100) {
            nbUserShowReelClicked = 0;
        } else {
            nbUserShowReelClicked = nbUserShowReelClicked + 1;
        }

        if (nbUserShowReelClicked != 0 && nbUserShowReelClicked % 5 === 0) {
            showInterstitial();
        }

        setItem('nbUserShowReelClicked', nbUserShowReelClicked);
    }

    const goLeft = () => {
        if(currentPosition > 0) {
            setCopied(false);
            let newPosition = currentPosition - 1;
            setCurrentPosition(newPosition);
            let prevId = getElementOfArrayById(reelIds, newPosition);
            getReelById(prevId);
        }
    }

    function likeReel() {
        setShowHeart(false);
        fetch(`${REACT_APP_API_URL}/api/reel/${reel.id}/like`, {
            method: "POST",
            headers: apiHeaders,
        })
        .then(response => response.json())
        .then((result) => {
            setReel(result);
            setShowHeart(true);
        }).catch((error) => {
            setShowHeart(true);
        });
    }

    let marginValue = "15px";
    if (REACT_APP_IS_IOS_APP === "true") {
        marginValue = "35px";
    }

    const {t} = useTranslation();

    async function copyLinkReel() {
        let uri = `${REACT_APP_FRON_URL}/reels/${themeId}?id=${reel.id}`;
        await Clipboard.write({
            string: uri
          });
        
        setCopied(true);
        setTimeout(function(){
            setCopied(false);
        }, 5000);
    }

    return (
        <Fragment>
            <Header />
            <InitializeAndHideBanner />
            <section className="blog-section padding-tb" style={width < 576 ? {marginTop: marginValue} : {}}>
                <div className="main-blog" style={{position: "fixed",top: 0,left: 0,width: "100vw",height: "100vh",zIndex: 9999, background: "#000"}}>
                    <div className="row g-4">
                        <div className="col-xl-12 col-12">
                            <div className="blog-wrapper">
                                <div className="post-item post-slider" style={{background: "#000"}}>
                                    <div className="post-item-inner">
                                        <div className="post-thumb">
                                            <div className="blog-slider">
                                                {reel.user ? (
                                                <div className="post-content" style={currentUserIdentifier === reel.user.username ? {
                                                    background: "#000", marginBottom: "-20px", marginLeft: "-40px" } : {
                                                    background: "#000", marginBottom: "-100px", marginLeft: "-40px"}}>
                                                    <div className="post-author">
                                                        <div className="post-author-inner">
                                                            <div className="author-thumb">
                                                                <NavLink to={`/profile/${reel.user.username}`} >
                                                                    <img 
                                                                        src={reel.user.publicPathCropedProfilePicture} 
                                                                        alt="img" 
                                                                        className="rounded-circle"
                                                                    />
                                                                </NavLink>       
                                                            </div>
                                                            <div className="author-details">
                                                                <h6>
                                                                    <NavLink to={`/profile/${reel.user.username}`} style={{color:"#fff"}}>
                                                                        {getProfileName(reel.user.username, reel.user.firstName ?? '', reel.user.lastName ?? '', true)}
                                                                        {reel.user.verified ? (<i className="icofont-check verified-user" title="Verify Profile"></i>) : null }
                                                                    </NavLink>
                                                                </h6>
                                                                <ul className="post-status">
                                                                    <li className="post-time">
                                                                    {(() => {                                        
                                                                            if (currentLanguage === "en") {
                                                                                return (
                                                                                <>
                                                                                    {reel.creationDateInArray.int_val ? (<>{reel.creationDateInArray.int_val}</>) : null} {t(reel.creationDateInArray.str_val)}                                            
                                                                                    {reel.creationDateInArray.str_val !== "now" ? (<>&nbsp;{t('ago')}</>) : null}
                                                                                </>); 
                                                                            }

                                                                            return (<>
                                                                            {reel.creationDateInArray.str_val !== "now" ? (<>{t('ago')}&nbsp;</>) : null}
                                                                            {reel.creationDateInArray.int_val ? (<>{reel.creationDateInArray.int_val}</>) : null} {t(reel.creationDateInArray.str_val)}</>)
                                                                        })()}
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                ) : null}

                                                <SignalActivity 
                                                    activityId={reel.id} 
                                                    currentUserIdentifier={currentUserIdentifier}
                                                    activityUserIdentifier={reel.user ? reel.user.username : null}
                                                    activities={null}
                                                    setActivities={null}
                                                    isReelPage={true}
                                                />
                                                <div className="blog-slider">
                                                    <div className="navigation-overlay"  style={{marginTop: "50px"}}>
                                                        <div
                                                            className="blog-slider-prev"
                                                            onClick={goLeft}
                                                            style={{ marginTop: "50px", cursor: "pointer", width: "50%", float: "left", height: heightVideo, opacity: "0", borderRadius: "0%" }}
                                                        ></div>
                                                        <div
                                                            className="blog-slider-next"
                                                            onClick={goRight}
                                                            style={{ marginTop: "50px", cursor: "pointer", width: "50%", float: "right", height: heightVideo, opacity: "0", borderRadius: "0%" }}
                                                        ></div>
                                                    </div>
                                                    <video
                                                        width="100%"  
                                                        preload="none" 
                                                        controls={false}
                                                        tabIndex="-1" 
                                                        playsInline 
                                                        aria-label="Video integree" 
                                                        src={reel.media ? reel.media.publicPath : null} 
                                                        poster={reel.publicPosterUrl}
                                                        muted={false}
                                                        style={{
                                                            height: heightVideo
                                                        }}
                                                        loop={true}
                                                        ref={videoRef}
                                                    >
                                                    </video>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="post-content" style={{textAlign: "center", background: "#000", color: "#fff"}}>
                                            <span>
                                                {showHeart ? (
                                                    <a onClick={likeReel} style={reel.likedByCurrentUser ? {color: "#f24570", cursor: "pointer"} : {cursor: "pointer"}} >
                                                        <i className="icofont-heart-alt"></i>  {reel.prettyNbLikes}&nbsp;&nbsp;
                                                    </a>
                                                ) : null} 
                                                |&nbsp;&nbsp;<i className="icofont-eye"></i> {reel.prettyNbViews}&nbsp;&nbsp;
                                                |&nbsp;&nbsp;
                                                {copied ? (
                                                    <a onClick={copyLinkReel} style={{cursor: "pointer"}}>
                                                        <i className="icofont-verification-check"></i>
                                                        &nbsp;&nbsp;{t('copied')}
                                                    </a>
                                                ) : (
                                                    <a onClick={copyLinkReel} style={{cursor: "pointer"}}>
                                                        <i className="icofont-share"></i>
                                                        &nbsp;&nbsp;{t('share')}
                                                    </a>
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}
