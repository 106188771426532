import { Fragment, useContext, useEffect, useState } from "react";
import HeaderTwo from "../component/layout/headertwo";
import Activity from "./user/activity";
import Auth from "../context/Auth";
import { getInitialUserData } from "../service/User";
import { getItem } from "../service/LocalStorage";
import Footer from "../component/layout/mobilefooter";
import { useWindowSize } from "../service/Window";
import InitializeAndResumeBanner from "../component/adMob/initializeBanner";
import TevifSpinner from "../component/spinner/tevifSpinner";
import PositionUser from "./map/postionUser";
import MediaTevif from "../component/MediaTevif/MediaTevif";

const {REACT_APP_API_URL} = process.env;
const ACTIVITIES_LIMIT = 3;

export default function NewsActivity() {
    let userToken = getItem('userToken');
    
    const {isAuthenticated, setIsAuthenticated} = useContext(Auth);
    const [user, setUser] = useState(getInitialUserData());
    const [activities, setActivities] = useState([]);
    const [spinnerActivities, setSpinnerActivities] = useState(false);
    const [width, height] = useWindowSize();

    const [firstCall, setFirstCall] = useState(false);

    useEffect(() => { 
        async function fetchUser() {
            let config = { 
                "Content-Type" : "application/json",
                "Authorization": "Bearer "+ userToken 
            };

            let url = `${REACT_APP_API_URL}/api/users/current?allData=0`;

            try {
                const userResponse = await fetch(url, {
                    method: "GET",
                    headers: config
                })
                
                const userData = await userResponse.json();
                setUser(userData);
            } catch (err) {
            } 
        }

        async function getActivities() {
            let config = { 
                "Content-Type" : "application/json",
                "Authorization": "Bearer "+ userToken 
            };
    
            let url = `${REACT_APP_API_URL}/api/activities/news?limit=${ACTIVITIES_LIMIT}`;
    
            try {
                setSpinnerActivities(true);
                const userResponse = await fetch(url, {
                    method: "GET",
                    headers: config
                })
                
                const data = await userResponse.json();
                setActivities(data.items);
                setSpinnerActivities(false);
            } catch (err) {
                setSpinnerActivities(false);
            } 
        }

        setFirstCall(true);

        if(firstCall) {
            fetchUser();
            getActivities();
        }

    }, [firstCall]);

    let marginValue = "35px";
    if (process.env.REACT_APP_IS_IOS_APP === "true") {
        marginValue = "55px";
    }

    return (
        <Fragment>
            <HeaderTwo />
            <InitializeAndResumeBanner />
            <section className="profile-section padding-tb banner-admob-margin" style={width < 576 ? {marginTop: marginValue} : {}}>
                    <div className="container">
                        <div className="section-wrapper">
                            <div className="member-profile">
                                <div className="profile-details">
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className="tab-pane activity-page fade show active" id="activity" role="tabpanel">
                                            <div className="col-xl-12" style={{maxWidth:"856px", margin:"auto"}}>
                                                <article>
                                                    <div className="activity-tab">
                                                        <MediaTevif pageName={'newsActivity'} />
                                                        <PositionUser currentPage={"news-activities"} />
                                                        {
                                                            spinnerActivities ? (
                                                                <TevifSpinner/>
                                                            ) : (
                                                                <Activity 
                                                                    userProfile={user}
                                                                    activities={activities}
                                                                    setActivities={setActivities}
                                                                    isAuthenticated={isAuthenticated}
                                                                    currentPage="newActivity"
                                                                />
                                                            )
                                                        }
                                                    </div>
                                                </article>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
        </Fragment>
    );
}